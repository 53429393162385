import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceSettings: [],
  convertedDeviceSettings: [],
};

const deviceDetailsSlice = createSlice({
  name: "deviceDetails",
  initialState,
  reducers: {
    deviceSettingsSet(state, action) {
      state.deviceSettings = action.payload;
    },
    // The difference between deviceSettings and convertedDeviceSettings is
    // that the convertedDeviceSettings are run through the convertShadow function
    // in ./src/helpers/convert.js.
    convertedDeviceSettingsSet(state, action) {
      state.convertedDeviceSettings = action.payload;
    },
  },
});

export const { deviceSettingsSet, convertedDeviceSettingsSet } =
  deviceDetailsSlice.actions;

export default deviceDetailsSlice.reducer;
