import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  searchKeys: [
    {
      name: "alias",
      // Prioritize alias over the other fields
      // https://fusejs.io/examples.html#weighted-search
      weight: 3,
    },
    {
      name: "licenseStatusText",
      weight: 2,
    },
    "hardwareSerial",
  ],
  invalidSearchTermValues: ["", undefined, null, false],
};

const subscriptionSearchSlice = createSlice({
  name: "subscriptionSearch",
  initialState,
  reducers: {
    searchTermChanged(state, action) {
      state.searchTerm = action.payload;
    },
    searchCleared(state) {
      state.searchTerm = "";
    },
  },
});

export const { searchTermChanged, searchCleared } =
  subscriptionSearchSlice.actions;

export default subscriptionSearchSlice.reducer;
