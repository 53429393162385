import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  searchKeys: [
    "email",
    "name",
    "adminPermissions",
    "activeStatus",
    "mfaEnabled",
    "lastLogin",
  ],
  invalidSearchTermValues: ["", undefined, null, false],
};

const teamSearchSlice = createSlice({
  name: "teamSearch",
  initialState,
  reducers: {
    searchTermChanged(state, action) {
      state.searchTerm = action.payload;
    },
    searchCleared(state) {
      state.searchTerm = "";
    },
  },
});

export const { searchTermChanged, searchCleared } = teamSearchSlice.actions;

export default teamSearchSlice.reducer;
