import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favoritesLoading: {
    isLoading: false,
    favoriteDeviceId: "",
  },
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    favoriteLoadingSet(state, action) {
      state.favoritesLoading = action.payload;
    },
  },
});

export const { favoriteLoadingSet } = favoritesSlice.actions;

export default favoritesSlice.reducer;
