import { createSlice } from "@reduxjs/toolkit";
import { allUpdatesReceived } from "../softwareUpdateScheduler/slice";
import {
  DEVICE_UPDATE_STATUSES,
  UPDATE_STATUSES,
} from "../softwareUpdateScheduler/constants";

/**
 * Checks for failed devices in a list of scheduled updates and adds/removes
 * alerts as needed.
 * @param {Array} updates - List of scheduled updates
 * @param {Object} state - Current needsAttentionSlice state
 */
const checkForFailedStatusesInScheduledUpdates = (updates, state) => {
  const type = "DeviceFailedToUpdate";

  updates.forEach((update) => {
    const devices = update?.devicesByMacAddress;
    if (!devices) {
      return;
    }

    const shouldAddAlert =
      update.status === UPDATE_STATUSES.PARTIAL_COMPLETE ||
      update.status === UPDATE_STATUSES.FAILED;

    if (shouldAddAlert) {
      // Add alert
      const id = `DeviceFailedToUpdate_${update.id}`;

      const existingAlert = state.itemsThatNeedAttention.find(
        (item) => item.id === id
      );

      const numberOfFailedDevices = Object.values(devices)?.filter(
        (device) => device.status === DEVICE_UPDATE_STATUSES.FAILED
      ).length;

      if (numberOfFailedDevices > 0) {
        if (!existingAlert) {
          state.itemsThatNeedAttention.push({
            id,
            type,
            data: {
              numberOfFailedDevices,
              ScheduleUpdateDevices: update?.ScheduleUpdateDevices,
              updateId: update.id,
            },
            sortDate: update.updatedAt,
            hasBeenDismissed: false,
            targetedUpdateTime: update.targetedUpdateTime,
          });
        } else {
          // Update existing alert
          state.itemsThatNeedAttention = state.itemsThatNeedAttention.map(
            (item) => {
              if (item.id === id) {
                item.data = {
                  numberOfFailedDevices,
                  ScheduleUpdateDevices: update?.ScheduleUpdateDevices,
                  updateId: update.id,
                };
                item.sortDate = update.updatedAt;
                item.hasBeenDismissed = false;
              }
              return item;
            }
          );
        }
      }
    } else {
      // Is cancelled or complete? Remove alert
      state.itemsThatNeedAttention = state.itemsThatNeedAttention.filter(
        (item) => item.id !== `DeviceFailedToUpdate_${update.id}`
      );
    }
  });
};

const needsAttentionSlice = createSlice({
  name: "needsAttention",
  initialState: {
    itemsThatNeedAttention: [],
  },
  reducers: {
    scheduledSoftwareUpdateEventReceivedForCheck: (state, action) => {
      checkForFailedStatusesInScheduledUpdates(action.payload, state);
    },
    fetchedLocallyStoredClosedIds: (state, action) => {
      const storedNeedsAttentionIdsParsed = action.payload;
      state.itemsThatNeedAttention.forEach((item) => {
        item.hasBeenDismissed = storedNeedsAttentionIdsParsed.includes(item.id);
      });
    },
  },
  extraReducers: (builder) => {
    builder
      // Main batch of scheduled updates received
      .addCase(allUpdatesReceived, (state, action) => {
        checkForFailedStatusesInScheduledUpdates(action.payload, state);
      });
  },
});

export const {
  fetchedLocallyStoredClosedIds,
  scheduledSoftwareUpdateEventReceivedForCheck,
} = needsAttentionSlice.actions;

export default needsAttentionSlice.reducer;
