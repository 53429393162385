export const UPDATE_STATUSES = {
  SCHEDULED: "Scheduled",
  IN_PROGRESS: "In Progress",
  PARTIAL_COMPLETE: "Partial Complete",
  CANCELLED: "Cancelled",
  FAILED: "Failed",
  COMPLETE: "Complete",
};

export const DEVICE_UPDATE_STATUSES = {
  PENDING: "Pending",
  UPDATING: "Updating",
  SUCCESSFUL: "Successful",
  CANCELLED: "Cancelled",
  FAILED: "Failed",
};

//used to view scheduler with update summary information
// TODO: This includes CANCELLED and PARTIAL_COMPLETE which makes this name misleading
export const updateFailedOrCompleted = (update) =>
  update?.status === UPDATE_STATUSES.COMPLETE ||
  update?.status === UPDATE_STATUSES.CANCELLED ||
  update?.status === UPDATE_STATUSES.PARTIAL_COMPLETE ||
  update?.status === UPDATE_STATUSES.FAILED;

// TODO: This includes CANCELLED which makes this name misleading
//used in the use case when the buttons in the footer of the scheduler change to allow for Update Now Or Schedule Later
export const updateFailedOrPartialComplete = (update) =>
  update?.status === UPDATE_STATUSES.PARTIAL_COMPLETE ||
  update?.status === UPDATE_STATUSES.CANCELLED ||
  update?.status === UPDATE_STATUSES.FAILED;

//timezones needed for moment-timezone package
export const timeZones = [
  "Europe/Paris", // Central European Time
  "Etc/GMT", // Greenwich Mean Time
  "America/New_York", // Eastern Time
  "America/New_York", // Eastern Daylight Time
  "America/Chicago", // Central Time
  "America/Chicago", // Central Daylight Time
  "America/Denver", // Mountain Time
  "America/Los_Angeles", // Pacific Time
  "America/Los_Angeles", // Pacific Daylight Time
  "America/New_York", //undefined
];

//timezones needed for UI in Next
export const displayedTimeZones = [
  "Central European Time Zone",
  "Greenwich Mean Time Zone",
  "Eastern Time Zone",
  "Eastern Time Zone", //Handle Eastern Daylight Time
  "Central Time Zone",
  "Central Time Zone", //Handle Central Daylight Time
  "Mountain Time Zone",
  "Pacific Time Zone",
  "Pacific Time Zone", //Handle Pacific Daylight Time
  "Eastern Time Zone", // handle undefined
];

//abbreviated timezones being sent back from back-end
export const abbreviatedTimeZones = [
  "CET",
  "GMT",
  "EST",
  "EDT",
  "CST",
  "CDT",
  "MST",
  "PDT",
  "PST",
  "undefined", //handle undefined
];
