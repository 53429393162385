import React from "react";
import { OwlSpinner } from "@owl-labs/ui-components";

const FallbackSpinner = () => (
  <OwlSpinner
    color="blue"
    additionalClassNames="fixed left-[calc(50%-15px)] top-[calc(50%-15px)] z-10 inline-block h-7 w-7"
  />
);

export default FallbackSpinner;
