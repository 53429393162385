import React, { Suspense } from "react";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import { retry } from "./helpers/Utils";
import { createRoot } from "react-dom/client";
import FallbackSpinner from "./components/common/FallbackSpinner";
import FlagProvider from "@unleash/proxy-client-react";

const config = {
  appName: "Nest",
  url: process.env.REACT_APP_UNLEASH_API_URL,
  clientKey: process.env.REACT_APP_UNLEASH_API_TOKEN,
  environment: process.env.NODE_ENV,
  refreshInterval: 15, // How often (in seconds) the client should poll for updates
};

(async () => {
  const App = React.lazy(() =>
    retry(() => import(/* webpackChunkName: "App" */ "./App"))
  );

  const container = document.getElementById("root");
  const root = createRoot(container);

  root?.render(
    <Provider store={store}>
      <FlagProvider config={config}>
        <Suspense fallback={<FallbackSpinner />}>
          <App />
        </Suspense>
      </FlagProvider>
    </Provider>
  );

  /*
   * If you want your app to work offline and load faster, you can change
   * unregister() to register() below. Note this comes with some pitfalls.
   * Learn more about service workers: https://bit.ly/CRA-PWA
   */
  serviceWorker.unregister();
})();
