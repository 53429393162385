import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDevicesWithSettingsArray: [],
  isMixedValuesPasscode: false,
};

const allDevicesSettingsSlice = createSlice({
  name: "allDevicesSettings",
  initialState,
  reducers: {
    isMixedValuesPasscodeSet(state, action) {
      state.isMixedValuesPasscode = action.payload;
    },
  },
});

export const { isMixedValuesPasscodeSet } = allDevicesSettingsSlice.actions;

export default allDevicesSettingsSlice.reducer;
