import { createSlice } from "@reduxjs/toolkit";
import { UPDATE_STATUSES } from "./constants";

const initialState = {
  softwareUpdateSchedulerOpen: null,
  softwareUpdateSchedulerUpdateId: null,
  softwareUpdateSchedulerUpdateStatus: null,
  softwareUpdateTargetedUpdateTime: null,
  softwareUpdateName: null,
  devicesToUpdate: {
    devices: [],
    updateNow: false,
  },
  pairedDevicesInUpdate: { devices: [] },
  scheduledSoftwareUpdates: [],
  scheduledSoftwareUpdatesModalOpen: null,
  allSoftwareUpdates: [],
  updateNotificationInfo: null,
  mostRecentlySavedScheduledUpdateID: null, //used for the 'Confirm/Edit' modal after scheduling update for device(s)
  ascending: null,
  defaultSortBy: "alias",
  currentSortBy: null,
  naturalSortKeys: [
    "alias",
    "hardwareSerial",
    "targetedSoftwareVersion",
    "deviceStatus",
  ],
  searchTerm: "",
  searchKeys: [
    "alias",
    "hardwareSerial",
    "softwareVersionString",
    "targetedSoftwareVersion",
    "ProductId",
    "deviceStatus",
  ],
  invalidSearchTermValues: ["", undefined, null, false],
  fetchingScheduledSoftwareUpdates: false,
};

const scheduler = createSlice({
  name: "scheduler",
  initialState,
  reducers: {
    openedSoftwareUpdateScheduler(state, action) {
      state.scheduledSoftwareUpdatesModalOpen = false;
      state.softwareUpdateSchedulerOpen = true;
      state.devicesToUpdate = {
        devices: action.payload.ScheduleUpdateDevices,
        updateNow: action.payload.updateNow ?? false,
      };
      state.softwareUpdateSchedulerUpdateId = action.payload.id;
      state.softwareUpdateSchedulerUpdateStatus = action.payload.status;
      state.softwareUpdateTargetedUpdateTime =
        action.payload.targetedUpdateTime;
      state.softwareUpdateName = action.payload.name;
    },
    closedSoftwareUpdateScheduler(state) {
      state.softwareUpdateSchedulerOpen = false;
      state.updateNotificationInfo = null;
    },
    devicesToUpdateSet(state, action) {
      state.devicesToUpdate = action.payload;
    },
    pairedDevicesInUpdateSet(state, action) {
      state.pairedDevicesInUpdate = action.payload;
    },
    openedScheduledSoftwareUpdatesModal(state) {
      state.scheduledSoftwareUpdatesModalOpen = true;
    },
    closedScheduledSoftwareUpdatesModal(state) {
      state.scheduledSoftwareUpdatesModalOpen = false;
    },
    allUpdatesReceived(state, action) {
      state.allSoftwareUpdates = action.payload;
    },
    summaryNotificationInfoSet(state, action) {
      state.updateNotificationInfo = action.payload;
    },
    mostRecentlySavedScheduledUpdateIDSet(state, action) {
      state.mostRecentlySavedScheduledUpdateID = action.payload;
    },
    // Individual scheduled update received
    scheduledSoftwareUpdateEventReceived(state, action) {
      const { ScheduleUpdateId, status } = action.payload;

      // Update scheduledSoftwareUpdates
      state.scheduledSoftwareUpdates = state.scheduledSoftwareUpdates.reduce(
        (newUpdates, update) => {
          if (update.id === ScheduleUpdateId) {
            // If status is FAILED or PARTIAL_COMPLETE, don't add to newUpdates
            if (
              status === UPDATE_STATUSES.FAILED ||
              status === UPDATE_STATUSES.PARTIAL_COMPLETE
            ) {
              return newUpdates;
            }
            // If status is not FAILED, update status and add to newUpdates
            return [...newUpdates, { ...update, status }];
          }
          // If update.id !== ScheduleUpdateId, add to newUpdates without changes
          return [...newUpdates, update];
        },
        [] // Start with an empty array
      );

      // Update allSoftwareUpdates
      state.allSoftwareUpdates = state.allSoftwareUpdates.map((update) => {
        if (update.id === ScheduleUpdateId) {
          return {
            ...update,
            status,
          };
        }
        return update;
      });

      // If this is the update that is currently displayed, update status
      if (state.softwareUpdateSchedulerUpdateId === ScheduleUpdateId) {
        state.softwareUpdateSchedulerUpdateStatus = status;
      }
    },
    // Main batch of scheduled updates received
    scheduledSoftwareUpdatesReceived(state, action) {
      state.scheduledSoftwareUpdates = action.payload;
    },
    // Individual device update received for a scheduled update
    scheduledSoftwareDeviceUpdateEventReceived(state, action) {
      const { ScheduleUpdateId, mac_address, status } = action.payload;
      state.scheduledSoftwareUpdates = state.scheduledSoftwareUpdates.map(
        (update) => {
          if (update.id === ScheduleUpdateId) {
            return {
              ...update,
              devicesByMacAddress: {
                ...update.devicesByMacAddress,
                [mac_address]: {
                  ...update.devicesByMacAddress[mac_address],
                  status,
                },
              },
              ScheduledUpdateDevices: update.ScheduleUpdateDevices.map(
                (device) => {
                  if (device.deviceMacAddress === mac_address) {
                    return {
                      ...device,
                      status,
                    };
                  }
                  return device;
                }
              ),
            };
          }
          return update;
        }
      );
      if (state.softwareUpdateSchedulerUpdateId === ScheduleUpdateId) {
        state.devicesToUpdate = {
          devices: state.devicesToUpdate.devices.map((device) => {
            if (device.deviceMacAddress === mac_address) {
              return {
                ...device,
                status,
              };
            }
            return device;
          }),
        };
      }
    },
    searchTermChanged(state, action) {
      state.searchTerm = action.payload;
    },
    searchCleared(state) {
      state.searchTerm = "";
    },
    ascendingSet(state, action) {
      state.ascending = action.payload;
    },
    currentSortBySet(state, action) {
      state.currentSortBy = action.payload;
    },
  },
});

export const {
  openedSoftwareUpdateScheduler,
  closedSoftwareUpdateScheduler,
  devicesToUpdateSet,
  pairedDevicesInUpdateSet,
  scheduledSoftwareUpdatesReceived,
  openedScheduledSoftwareUpdatesModal,
  closedScheduledSoftwareUpdatesModal,
  scheduledSoftwareUpdateEventReceived,
  scheduledSoftwareDeviceUpdateEventReceived,
  allUpdatesReceived,
  summaryNotificationInfoSet,
  mostRecentlySavedScheduledUpdateIDSet,
  searchTermChanged,
  searchCleared,
  ascendingSet,
  currentSortBySet,
} = scheduler.actions;

export default scheduler.reducer;
