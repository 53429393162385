import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  owlNotificationsDrawerOpen: null,
  memberEmail: "",
  clickedNotificationId: null,
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    openedOwlNotificationsDrawer(state) {
      state.owlNotificationsDrawerOpen = true;
    },
    closedOwlNotificationsDrawer(state) {
      state.owlNotificationsDrawerOpen = false;
    },
    memberEmailSet(state, action) {
      state.memberEmail = action.payload;
    },
    clickedNotificationIdSet(state, action) {
      state.clickedNotificationId = action.payload;
    },
  },
});

export const {
  openedOwlNotificationsDrawer,
  closedOwlNotificationsDrawer,
  memberEmailSet,
  clickedNotificationIdSet,
} = notifications.actions;

export default notifications.reducer;
