import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onlyFrontOfRoomDevices: [],
  hasMixedValuesPresenterEnhanceFORDevice: false,
  hasMixedValuesLockAndZoom: false,
  hasMixedValuesAutoFrame: false,
  hasMixedValuesFlipVideoFORDevice: false,
};

const frontOfRoomDeviceSettingsSlice = createSlice({
  name: "frontOfRoomDeviceSettings",
  initialState,
  reducers: {
    frontOfRoomDevicesOnlySet(state, action) {
      state.onlyFrontOfRoomDevices = action.payload;
    },
    hasMixedValuesPresenterEnhanceFORDeviceSet(state, action) {
      state.hasMixedValuesPresenterEnhanceFORDevice = action.payload;
    },
    hasMixedValuesLockAndZoomSet(state, action) {
      state.hasMixedValuesLockAndZoom = action.payload;
    },
    hasMixedValuesAutoFrameSet(state, action) {
      state.hasMixedValuesAutoFrame = action.payload;
    },
    hasMixedValuesFlipVideoFORSet(state, action) {
      state.hasMixedValuesFlipVideoFORDevice = action.payload;
    },
    frontOfRoomDeviceSettingsCleared(state) {
      state.onlyFrontOfRoomDevices = [];
      state.hasMixedValuesPresenterEnhanceFORDevice = false;
      state.hasMixedValuesLockAndZoom = false;
      state.hasMixedValuesAutoFrame = false;
      state.hasMixedValuesFlipVideoFORDevice = false;
    },
  },
});

export const {
  frontOfRoomDevicesOnlySet,
  hasMixedValuesAutoFrameSet,
  hasMixedValuesLockAndZoomSet,
  hasMixedValuesFlipVideoFORSet,
  hasMixedValuesPresenterEnhanceFORDeviceSet,
  frontOfRoomDeviceSettingsCleared,
} = frontOfRoomDeviceSettingsSlice.actions;

export default frontOfRoomDeviceSettingsSlice.reducer;
