import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  owlConnectDeviceMacAddress: "",
  owlConnectDrawerOpen: false,
};

const owlConnectDevicesDrawerSlice = createSlice({
  name: "owlConnectDevicesDrawer",
  initialState,
  reducers: {
    owlConnectDevicesDrawerDeviceMacAddressSet(state, action) {
      state.owlConnectDeviceMacAddress = action.payload;
    },
    openedOwlConnectDevicesDrawer(state) {
      state.owlConnectDrawerOpen = true;
    },
    closedOwlConnectDevicesDrawer(state) {
      state.owlConnectDrawerOpen = false;
    },
  },
});

export const {
  owlConnectDevicesDrawerDeviceMacAddressSet,
  openedOwlConnectDevicesDrawer,
  closedOwlConnectDevicesDrawer,
} = owlConnectDevicesDrawerSlice.actions;

export default owlConnectDevicesDrawerSlice.reducer;
