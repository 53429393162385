import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ascending: null,
  defaultSortBy: "alias",
  currentSortBy: null,
  naturalSortKeys: ["alias", "activated", "hardwareSerial", "licenseStatus"],
};

const subscriptionSortSlice = createSlice({
  name: "subscriptionSort",
  initialState,
  reducers: {
    ascendingSet(state, action) {
      state.ascending = action.payload;
    },
    currentSortBySet(state, action) {
      state.currentSortBy = action.payload;
    },
  },
});

export const { ascendingSet, currentSortBySet } = subscriptionSortSlice.actions;

export default subscriptionSortSlice.reducer;
