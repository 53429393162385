import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  onlyMeetingOwls: [],
  hasMixedValuesPresenterEnhanceMeetingOwl: false,
  hasMixedValuesPano: false,
  hasMixedValuesFlipVideoMeetingOwl: false,
  hasMixedValuesAlternativeIndicators: false, //MO4Plus Only
};

const meetingOwlsSettingsSlice = createSlice({
  name: "meetingOwlSettings",
  initialState,
  reducers: {
    meetingOwlsOnlySet(state, action) {
      state.onlyMeetingOwls = action.payload;
    },
    hasMixedValuesPresenterEnhanceMeetingOwlSet(state, action) {
      state.hasMixedValuesPresenterEnhanceMeetingOwl = action.payload;
    },
    hasMixedValuesPanoSet(state, action) {
      state.hasMixedValuesPano = action.payload;
    },
    hasMixedValuesFlipVideoMeetingOwlSet(state, action) {
      state.hasMixedValuesFlipVideoMeetingOwl = action.payload;
    },
    hasMixedValuesAlternativeIndicatorsSet(state, action) {
      state.hasMixedValuesAlternativeIndicators = action.payload;
    },
    meetingOwlDeviceSettingsCleared(state) {
      state.onlyMeetingOwls = [];
      state.hasMixedValuesPresenterEnhanceMeetingOwl = false;
      state.hasMixedValuesPano = false;
      state.hasMixedValuesFlipVideoMeetingOwl = false;
      state.hasMixedValuesAlternativeIndicators = false;
    },
  },
});

export const {
  meetingOwlsOnlySet,
  hasMixedValuesFlipVideoMeetingOwlSet,
  hasMixedValuesPanoSet,
  hasMixedValuesPresenterEnhanceMeetingOwlSet,
  hasMixedValuesAlternativeIndicatorsSet,
  meetingOwlDeviceSettingsCleared,
} = meetingOwlsSettingsSlice.actions;

export default meetingOwlsSettingsSlice.reducer;
