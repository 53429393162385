import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sessionBannerIsDisplayed: false,
};

const sessionBanner = createSlice({
  name: "sessionBanner",
  initialState,
  reducers: {
    sessionBannerToggled(state, action) {
      state.sessionBannerIsDisplayed = action.payload;
    },
  },
});

export const { sessionBannerToggled } = sessionBanner.actions;

export default sessionBanner.reducer;
