import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingStatuses: true,
  connectionStatuses: {},
  meetingStatuses: {},
  owlnetStatuses: {},
};

const iotSlice = createSlice({
  name: "iot",
  initialState,
  reducers: {
    connectionStatusReceived(state, action) {
      const { mac_address, value } = action.payload;
      state.connectionStatuses[mac_address] = value;
    },
    meetingStatusReceived(state, action) {
      const { mac_address, value } = action.payload;
      state.meetingStatuses[mac_address] = value;
    },
    owlnetStatusReceived(state, action) {
      const { mac_address, value } = action.payload;
      state.owlnetStatuses[mac_address] = value;
    },
    IoTStatusesReceived(state, action) {
      state.connectionStatuses = action.payload?.connection_status;
      state.meetingStatuses = action.payload?.meeting_status;
      state.owlnetStatuses = action.payload?.owlnet_status;
      state.isLoadingStatuses = false;
    },
    IoTStatusesNotReceivedAfterTimeout(state) {
      state.isLoadingStatuses = false;
    },
  },
});

export const {
  connectionStatusReceived,
  meetingStatusReceived,
  owlnetStatusReceived,
  IoTStatusesReceived,
  IoTStatusesNotReceivedAfterTimeout,
} = iotSlice.actions;

export default iotSlice.reducer;
