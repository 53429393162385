import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ascending: null,
  defaultSortBy: "email",
  currentSortBy: null,
  naturalSortKeys: [
    "email",
    "name",
    "adminPermissions",
    "activeStatus",
    "mfaEnabled",
    "lastLogin",
  ],
};

const teamSortSlice = createSlice({
  name: "teamSort",
  initialState,
  reducers: {
    ascendingSet(state, action) {
      state.ascending = action.payload;
    },
    currentSortBySet(state, action) {
      state.currentSortBy = action.payload;
    },
  },
});

export const { ascendingSet, currentSortBySet } = teamSortSlice.actions;

export default teamSortSlice.reducer;
